import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import programListStore from '@/store/modules/programList';
import {
  checkCMSEnabledReRoute, getCopyrightMessage,
  isAuthorizedContent,
  LearnAboutPageData,
  learnAboutResponseToData,
} from '@/utilities/cmsUtilities';
import APP_CONST from '@/constants/AppConst';
import axios from 'axios';
import {getContentfulGraphQLQuery, getRelatedContentfulGraphQLQuery} from '@/utilities/contentfulGraphQLHelpers';
import CmsNotAuthorizedComponent from '@/commoncomponents/CmsNotAuthorizedComponent/CmsNotAuthorizedComponent.vue';
import {LabelData, LabelType} from '@/commoncomponents/CmsLabelComponent/CmsLabelComponent';
import CmsLabelComponent from '@/commoncomponents/CmsLabelComponent/CmsLabelComponent.vue';
import CmsTagComponent from '@/commoncomponents/CmsTagComponent/CmsTagComponent.vue';
import CmsCardCarousel from '@/commoncomponents/CmsCardCarousel/CmsCardCarousel.vue';
import CmsContentfulComponent from '@/commoncomponents/CmsContentfulComponent/CmsContentfulComponent.vue';
import PrintButton from '@/commoncomponents/PrintButton/PrintButton.vue';
import ExcludePrint from '@/commoncomponents/ExcludePrint/ExcludePrint.vue';
import {Elements} from '@/commoncomponents/ExcludePrint/ExcludePrint';

@Component({
  name: 'learn-about-landing-page',
  components: {
    ExcludePrint,
    PrintButton,
    CmsCardCarousel,
    'bouncing-preloader': BouncingPreloaderComponent,
    'cms-not-authorized': CmsNotAuthorizedComponent,
    'cms-content-label': CmsLabelComponent,
    'cms-contentful-render': CmsContentfulComponent,
    'cms-tag-list': CmsTagComponent
  }
})
export default class LearnAboutLandingPage extends Vue {
  contentId: string = '';
  loading: boolean = false;
  isAuthorized: boolean = false;
  isPrinting: boolean = false;
  pageData: LearnAboutPageData | null = {
    id: '',
    title: '',
    itemDescription: '',
    itemShortDescription: '',
    heroImage: '',
    learnAboutContent: {},
    relatedContent: {}
  };
  labelData: LabelData[] = [{
    type: LabelType.TYPE,
    title: 'Type:',
    value: 'Learn About'
  }];
  richContentElementsToExclude: string[] = [Elements.VIDEO];

  @Prop()
  contentIdQuery: string | undefined;

  get lxEnabled() {
    return programListStore.appSetting;
  }

  @Watch('lxEnabled', {immediate: true, deep: true})
  cmsFeatureFlag(store: any) {
    if(checkCMSEnabledReRoute(store)) {
      this.$router.push({ path: '/' });
    }
  }

  async fetchData() {
    const url = `${APP_CONST.CONTENTFUL_GRAPHQL_URL}${APP_CONST.CONTENTFUL_GRAPHQL_SPACE}${APP_CONST.CONTENTFUL_GRAPHQL_ENV}`;
    const headers = { Authorization: `Bearer ${APP_CONST.CONTENTFUL_GRAPHQL_TOKEN}` };
    const method = 'post';
    this.loading = true;
    try {
      const result = await axios({
        url,
        headers,
        method,
        data: {
          query: getContentfulGraphQLQuery('learnAbout', this.contentId)
        }
      });
      const relatedResult = await axios({
        url,
        headers,
        method,
        data: {
          query: getRelatedContentfulGraphQLQuery('learnAbout', this.contentId, false)
        }
      });
      const structuredData = learnAboutResponseToData({ ...result, related: { ...relatedResult } });
      this.isAuthorized = isAuthorizedContent(structuredData);
      this.pageData = { ...structuredData };
      this.loading = false;
    }
    catch (err) {
      console.error(`Failed to fetch landing page`, err);
    }
  }

  async handleUpdatedId() {
    this.contentId = this.$route.query.id as string || '';
    await this.fetchData();
  }

  getCopyrightMessage = getCopyrightMessage;

  handleAfterPrint() {
    this.isPrinting = false;
    this.$forceUpdate();
  }

  handleBeforePrint() {
    this.isPrinting = true;
  }

  handlePrint() {
    this.isPrinting = true;
    this.$forceUpdate();
    this.$nextTick(() => {
      setTimeout(() => {
        print();
        this.$forceUpdate();
      }, 1000);
    });
  }

  async beforeMount() {
    this.contentId = this.$route.query.id as string || '';
    this.$watch('$route', this.handleUpdatedId);
    await this.fetchData();
  }

  created() {
    window.addEventListener('beforeprint', this.handleBeforePrint);
    window.addEventListener('afterprint', this.handleAfterPrint);
  }

  destroyed() {
    window.removeEventListener('beforeprint', this.handleBeforePrint);
    window.removeEventListener('afterprint', this.handleAfterPrint);
  }
}
